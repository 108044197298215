const routes = [
  {
    path: "/gestor/relatorios/atividade",
    component: () =>
      import("@/views/gestor/relatorios/RelatoriosAtividades.vue"),
    meta: {
      menu: "dashboard",
      title: "Relatorio-Atividade",
      componente: "vueGestorRelatoriosAtividades",
      type: "menu",
    },
  },
  {
    path: "/gestor/relatorios/atividade-colaborador",
    component: () =>
      import(
        "@/views/gestor/relatorios/RelatoriosAtividadesPorColaborador.vue"
      ),
    meta: {
      menu: "dashboard",
      title: "Relatorio-Atividade-Colaborador",
      componente: "vueGestorRelatoriosAtividadesColaborador",
      type: "menu",
    },
  },
  {
    path: "/gestor/relatorios/atividade-cliente",
    component: () =>
      import("@/views/gestor/relatorios/RelatoriosAtividadesPorCliente.vue"),
    meta: {
      menu: "dashboard",
      title: "Relatorio-Atividade-Cliente",
      componente: "vueGestorRelatoriosAtividadesCliente",
      type: "menu",
    },
  },
  /*------------------------------------------------------------------------------------------------*/
];
export default routes;
