const initialState = () => ({
  filtros: {
    titulo: null,
    status: null,
    prioridade: null,
    servico: null,
    dataFrom: null,
    dataTo: null,
    cliente_id: null,
    colaborador_id: null,
    dataColuna: 'inicio',
    clientes_colaborador: [],
  },
});

const state = initialState();

// getters
const getters = {};

// actions
const actions = {
  reset({ commit }) {
    commit("RESET");
  },
};

// mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  UPDATE_TITULO(state, payload) {
    state.filtros.titulo = payload;
  },
  UPDATE_STATUS(state, payload) {
    state.filtros.status = payload;
  },
  UPDATE_PRIORIDADE(state, payload) {
    state.filtros.prioridade = payload;
  },
  UPDATE_CLIENTE_SERVICO(state, payload) {
    state.filtros.servico = payload;
  },
  UPDATE_DATAFROM(state, payload) {
    state.filtros.dataFrom = payload;
  },
  UPDATE_DATATO(state, payload) {
    state.filtros.dataTo = payload;
  },
  UPDATE_CLIENTE(state, payload) {
    state.filtros.cliente_id = payload;
  },
  UPDATE_COLABORADOR(state, payload) {
    state.filtros.colaborador_id = payload;
  },
  UPDATE_DATA_COLUNA(state, payload) {
    state.filtros.dataColuna = payload;
  },
  UPDATE_CLIENTES_COLABORADOR(state, payload) {
    state.filtros.clientes_colaborador = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
